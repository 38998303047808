<template>
  <div v-if="isLoading">
    <v-skeleton-loader
      type="table-heading"
      class="mb-4"
    />

    <v-row
      class="pb-5"
      style="
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
      "
    >
      <v-col
        v-for="loadingIdx of 24"
        :key="`loading${loadingIdx}`"
      >
        <v-skeleton-loader type="card" />
      </v-col>
    </v-row>
  </div>

  <div
    v-else
  >
    <v-tabs
      v-if="hallTables.length > 1"
      v-model="currentHallIdx"
      show-arrows
      background-color="transparent"
      class="mb-5 elevation-0"
    >
      <v-tab
        v-for="(hall, hallTabIdx) in hallTables"
        :key="`hall_tab_${hallTabIdx}`"
      >
        {{ hall.attributes.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="currentHallIdx"
      style="background: transparent"
      touchless
      class="px-2"
    >
      <v-tab-item
        v-for="(hall, hallIdx) in hallTables"
        :key="`hall_${hallIdx}`"
      >
        <v-row
          class="pb-5"
          style="
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(272px, 1fr));
          "
        >
          <v-col
            v-for="(table, tableIdx) of hall.tables"
            :key="`table_${tableIdx + 1}`"
          >
            <div v-if="table && table.hold === undefined">
              <table-in-use
                :table-number="tableIdx + 1"
                :table-prefix="hall.attributes.tablePrefix"
                :table-suffix="hall.attributes.tableSuffix"
                :start-at="table.attributes.startAt"
                :customer-count="table.attributes.customerCount"
                :subtotal="table.meta.subtotal"
                :total="table.meta.total"
                :expire-at="table.meta.expireAt"
                :current-nominations="table.meta.currentNominations.data"
                :referring-histories="table.attributes.referringHistories.data"
                :customer-table-taggings="table.attributes.customerTableTaggings.data"
                @clicked="openShowTable({ hallIdx, tableIdx })"
                @move-table="moveTable(hall.id, table.id, $event)"
              />

              <table-show-drawer
                v-if="table"
                :is-visible="table && targetTableActions.show && targetTable.hallIdx === hallIdx && targetTable.tableIdx === tableIdx"
                :table-id="table.id"
                :table-number="tableIdx+1"
                :table-prefix="hall.attributes.tablePrefix"
                :table-suffix="hall.attributes.tableSuffix"
                :start-at="table.attributes.startAt"
                :customer-count="table.attributes.customerCount"
                :subtotal="table.meta.subtotal"
                :total="table.meta.total"
                :paid-in-advance-amount="table.meta.paidInAdvanceAmount"
                :expire-at="table.meta.expireAt"
                :current-nominations="table.meta.currentNominations.data"
                :course-overview="table.meta.courseOverview.data"
                :customer-table-taggings="table.attributes.customerTableTaggings.data"
                :default-tab-idx="defaultTableShowTabIdx"
                @updated="updateTable($event)"
                @checked="checkoutTable(hallIdx, $event)"
                @close="closeDrawer({ strategy: null })"
              />
            </div>

            <div v-else>
              <table-not-in-use
                :table="table"
                :table-number="tableIdx+1"
                :table-prefix="hall.attributes.tablePrefix"
                :table-suffix="hall.attributes.tableSuffix"
                @clicked="openNewTable({ hallIdx, tableIdx })"
              />

              <table-create-drawer
                v-if="!table"
                :is-visible="!table && targetTableActions.create && targetTable.hallIdx === hallIdx && targetTable.tableIdx === tableIdx"
                :hall-id="hall.id"
                :table-number="tableIdx+1"
                :table-prefix="hall.attributes.tablePrefix"
                :table-suffix="hall.attributes.tableSuffix"
                :referrals="hall.attributes.tableInitConfig.data.attributes.referrals.data"
                :nominations="hall.attributes.tableInitConfig.data.attributes.nominations.data"
                :courses="hall.attributes.tableInitConfig.data.attributes.courses.data"
                :items="hall.attributes.tableInitConfig.data.attributes.items.data"
                @close="closeDrawer({ strategy: actionType.delete })"
                @created="addTable($event)"
              />
            </div>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <QrCode
      v-if="receipt.id && receipt.attributes.receiptImages.length > 0"
      :key="`receipt-${receipt.id}`"
      :urls="receipt.attributes.receiptImages"
      :immediate-share="true"
      class="mt-1"
      @closed="receipt = {}"
    />
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  watch,
  provide,
  inject,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  map,
  find,
  get,
  uniqBy,
  flatMap,
  findIndex,
  sumBy,
  cloneDeep,
  groupBy,
} from 'lodash'
import HallApi from '@/api/waiter/Hall'
import UserApi from '@/api/waiter/User'
import VendibleApi from '@/api/waiter/Vendible'
import ReferralApi from '@/api/waiter/Referral'
import VendingContributionTriggerApi from '@/api/waiter/VendingContributionTrigger'
import TimecardApi from '@/api/waiter/Timecard'
import TableApi from '@/api/waiter/Table'
import TableInUse from '@/views/components/table/TableInUse.vue'
import TableNotInUse from '@/views/components/table/TableNotInUse.vue'
import TableCreateDrawer from '@/views/components/table/TableCreateDrawer.vue'
import TableShowDrawer from '@/views/components/table/TableShowDrawer/TableShowDrawer.vue'
import useQueryString from '@/views/composable/useQueryString'
import useCurrentData from '@/views/composable/useCurrentData'
import useCompInit from '@/views/composable/useCompInit'
import useUserGuiding from '@/views/composable/useUserGuiding'
import { cable } from '@/plugins/websocket-client'
import QrCode from '@/views/components/printer/QrCode.vue'

export default {
  components: {
    TableInUse,
    TableNotInUse,
    TableCreateDrawer,
    TableShowDrawer,
    QrCode,
  },
  setup() {
    const vm = getCurrentInstance().proxy

    const { currentUser } = useCurrentData()
    const { id: currentUserId } = currentUser.value
    const currentHallIdx = ref(0)
    const targetTable = reactive({ hallIdx: undefined, tableIdx: undefined })
    const targetTableActions = reactive({
      create: false, show: false, created: false, updated: false,
    })
    const halls = ref([])
    const actionType = {
      new: 'new',
      create: 'create',
      delete: 'delete',
      confirm: 'confirm',
    }
    const { isLoading, initWith } = useCompInit()
    const { isAlwaysAssistOn, triggerGuide } = useUserGuiding()

    const currentClubMeta = inject('currentClubMeta', {})
    const getClub = inject('getClub', () => {})
    const fetchPlusMinusStats = inject('fetchPlusMinusStats', () => {})
    const fetchOperationRateStats = inject('fetchOperationRateStats', () => {})
    const noticeSubscription = inject('noticeSubscription', () => {})

    // NOTE: Hallの子供Comp全体で使える普遍的なやつはHallからprovideする
    const users = ref([])
    const vendibles = ref([])
    const courses = ref([])
    const nominations = ref([])
    const items = ref([])
    const referrals = ref([])
    const vendingContributionTriggers = ref([])
    const timecards = ref([])
    const receipt = ref({})

    provide('users', users)
    provide('vendibles', vendibles)
    provide('courses', courses)
    provide('nominations', nominations)
    provide('items', items)
    provide('referrals', referrals)
    provide('vendingContributionTriggers', vendingContributionTriggers)
    provide('timecards', timecards)
    provide('halls', halls)

    const { route, resetQuery } = useQueryString()

    const otherWaiterUsesTable = (hallIdx, tableNumber, fromUserId) => {
      if (fromUserId !== currentUserId) {
        const targetHallNumber = hallIdx + 1
        const targetTableNumber = tableNumber + 1

        halls.value[hallIdx].attributes.tables.data.push(
          {
            attributes: {
              hallId: targetHallNumber,
              tableNumber: targetTableNumber,
            },
            id: String(targetTableNumber),
            hold: true,
            meta: {},
          },
        )
      }
    }

    const releaseOtherWaiterUsesTable = (hallIdx, tableNumber, fromUserId) => {
      if (fromUserId !== currentUserId) {
        const targetTableNumber = tableNumber

        const targetHall = halls.value[hallIdx]
        if (!targetHall?.attributes) return

        const tableIdx = findIndex(
          targetHall.attributes.tables.data,
          table => Number(table.attributes.tableNumber) === Number(targetTableNumber),
        )
        targetHall.attributes.tables.data.splice(tableIdx, 1)
      }
    }
    const updateOtherWaiterTable = ({
      hallIdx, fromUserId, tableData,
    }) => {
      if (fromUserId !== currentUserId) {
        const targetHall = halls.value[hallIdx]
        if (!targetHall?.attributes) return

        const tableIdx = findIndex(
          targetHall.attributes.tables.data,
          table => Number(table.attributes.tableNumber) === Number(tableData.attributes.tableNumber),
        )
        targetHall.attributes.tables.data.splice(tableIdx, 1, tableData)
      }
    }

    const reservedOtherWaiterTable = fromUserId => {
      if (fromUserId !== currentUserId && targetTableActions.create) {
        const { hallIdx, tableIdx } = targetTable
        // eslint-disable-next-line no-use-before-define
        subscription.occupyPlace(hallIdx, tableIdx, 'new')
      }
    }

    const syncTables = hallIdx => {
      // eslint-disable-next-line no-use-before-define
      subscription.confirmReservedOccupyPlace(hallIdx)
    }

    const subscription = cable.subscriptions.create(
      {
        channel: 'HallChannel',
      },
      {
        connected: () => {},
        disconnected: () => {},
        received: ({
          hallIdx,
          tableNumber,
          fromUserId,
          strategy,
          tableData,
        }) => {
          // NOTE: 自分が以外のユーザが操作したときの通知で主に使われます
          switch (strategy) {
            case 'new':
              otherWaiterUsesTable(hallIdx, tableNumber, fromUserId)
              break
            case 'updateTable':
              updateOtherWaiterTable({
                hallIdx, tableData, fromUserId,
              })
              break
            case 'delete':
              releaseOtherWaiterUsesTable(hallIdx, tableNumber, fromUserId)
              break
            case 'confirm':
              reservedOtherWaiterTable(fromUserId)
              break
            default:
              break
          }
        },
        occupyPlace: (hallIdx, tableNumber, strategy) => {
          // NOTE: 対象のhallの該当のtableを保有する

          return subscription.perform('occupy_place', {
            hallIdx,
            tableNumber,
            currentUserId,
            strategy,
          })
        },
        deleteOccupyPlace: ({ hallIdx, tableNumber }) => {
          return subscription.perform('delete_occupy_place', {
            hallIdx,
            tableNumber,
            currentUserId,
          })
        },
        confirmReservedOccupyPlace: hallIdx => {
          return subscription.perform('confirm_reserved_occupy_place', {
            hallIdx,
            currentUserId,
          })
        },
        updateTable: ({ hallIdx, tableData }) => {
          return subscription.perform('update_table', {
            hallIdx,
            currentUserId,
            tableData,
          })
        },
      },
    )

    const vendibleTags = computed(() => {
      return uniqBy(flatMap(vendibles.value, 'attributes.tags.data'), 'id')
    })
    provide('vendibleTags', vendibleTags)

    const defaultTableShowTabIdx = computed(() => {
      if (route.value.query.vendingHistoryId || route.value.query.receipt) return 2

      return 0
    })

    const usersWithTimecard = computed(() => {
      // timecards.valueのコピーを作成し、start_atによって降順に並び替える
      const sortedTimecards = [...timecards.value].sort((a, b) => {
        // `start_at` を使用して降順にソート
        return b.attributes.startAt.localeCompare(a.attributes.startAt)
      })

      // props.usersの各ユーザーについて、最も最近のtimecardを見つける
      const usersWithLatestTimecard = users.value
        .map(user => {
          // 各ユーザーに紐づく最新のタイムカードを探す
          const userLatestTimecard = sortedTimecards.find(timecard => +timecard.attributes.userId === +user.id)

          // ユーザーに紐づくタイムカードがあればユーザー情報とマージし、タイムカードがなければnullを返す
          return userLatestTimecard
            ? {
              ...user,
              timecard: userLatestTimecard.attributes, // タイムカードがある場合はその属性を含む
            }
            : null // タイムカードがないユーザーの場合はnullを返す
        })
        .filter(user => user !== null) // nullでないユーザーのみを保持する

      return usersWithLatestTimecard
    })
    provide('usersWithTimecard', usersWithTimecard)

    const getHalls = async () => {
      const res = await HallApi.getHalls({ isOperation: true })

      if (res?.data) {
        halls.value = [...res.data.halls.data]
      }
    }

    const getUsers = async () => {
      const res = await UserApi.getUsers()

      if (res?.data) {
        users.value = [...res.data.users.data]
      }
    }

    const getVendibles = async () => {
      const res = await VendibleApi.getVendibles()

      if (res?.data) {
        vendibles.value = [...res.data.vendibles.data]

        const vendiblesByType = groupBy(vendibles.value, 'attributes.vendibleType')
        courses.value = vendiblesByType.Course
        nominations.value = vendiblesByType.Nomination
        items.value = vendiblesByType.Items
      }
    }

    const getReferrals = async () => {
      const res = await ReferralApi.getReferrals()

      if (res?.data) {
        referrals.value = [...res.data.referrals.data]
      }
    }

    const getVendingContributionTriggers = async () => {
      const res = await VendingContributionTriggerApi.getVendingContributionTriggers()

      if (res?.data) {
        vendingContributionTriggers.value = [...res.data.vendingContributionTriggers.data]
      }
    }

    const getTimecards = async () => {
      const res = await TimecardApi.getTimecards({ isToday: true })

      if (res?.data) {
        timecards.value = [...res.data.timecards.data]
      }
    }

    const arrangeTables = hall => {
      // 空席を用意する
      return map(Array(hall.attributes.tableCount).fill(null), (_, idx) => {
        const tables = get(hall, 'attributes.tables.data')

        return find(tables, table => Number(get(table, 'attributes.tableNumber')) === (idx + 1))
      })
    }

    const hallTables = computed(() => {
      return map(halls.value, hall => {
        return {
          ...hall,
          tables: arrangeTables(hall),
        }
      })
    })

    const activeTables = computed(() => {
      return flatMap(halls.value, hall => hall.attributes.tables.data)
    })

    const addTable = tableData => {
      const targetHall = find(halls.value, hall => Number(hall.id) === Number(get(tableData, 'attributes.hallId')))
      if (!targetHall?.attributes) return

      const { hallIdx } = targetTable
      targetHall.attributes.tables.data.push(tableData)

      // NOTE: 作成完了済みに変更
      targetTableActions.created = true
      subscription.updateTable({ hallIdx, tableData })

      Promise.all([getClub(), fetchPlusMinusStats(), fetchOperationRateStats()])
    }

    const updateTable = tableData => {
      const targetHall = find(halls.value, hall => Number(hall.id) === Number(get(tableData, 'attributes.hallId')))
      if (!targetHall?.attributes) return

      const tableIdx = findIndex(
        targetHall.attributes.tables.data,
        table => Number(table.attributes.tableNumber) === Number(tableData.attributes.tableNumber),
      )
      const { hallIdx } = targetTable

      targetHall.attributes.tables.data.splice(tableIdx, 1, tableData)
      subscription.updateTable({ hallIdx, tableData })

      Promise.all([getClub(), fetchPlusMinusStats(), fetchOperationRateStats()])
    }

    const openDrawer = (actionKey, { hallIdx, tableIdx, strategy = null }) => {
      // NOTE: テーブルの選択
      targetTable.hallIdx = hallIdx
      targetTable.tableIdx = tableIdx
      // NOTE: 作成 or 詳細確認の一致する方のフラグが変わる
      targetTableActions[actionKey] = true
      // NOTE: tableを占有したことをほかのメンバーに通知
      subscription.occupyPlace(hallIdx, tableIdx, strategy)
    }

    const openNewTable = ({ hallIdx, tableIdx }) => {
      openDrawer('create', { hallIdx, tableIdx, strategy: actionType.new })
      if (isAlwaysAssistOn.value) triggerGuide('ASSIST_NEW_TABLE')
    }

    const openShowTable = ({ hallIdx, tableIdx }) => {
      openDrawer('show', { hallIdx, tableIdx })
      if (isAlwaysAssistOn.value) triggerGuide('ASSIST_SHOW_TABLE')
    }

    const closeDrawer = ({ strategy }) => {
      // NOTE: テーブルの選択の解除
      const { hallIdx, tableIdx } = targetTable
      const { create, created } = targetTableActions
      targetTable.hallIdx = undefined
      targetTable.tableIdx = undefined
      targetTableActions.create = false
      targetTableActions.created = false
      targetTableActions.show = false
      targetTableActions.updated = false
      resetQuery(true)

      const tableNumber = tableIdx + 1

      if (create && !created) subscription.deleteOccupyPlace({ hallIdx, tableNumber })
    }

    const checkoutTable = async (hallIdx, receiptData) => {
      const tableIdx = findIndex(
        halls.value[hallIdx].attributes.tables.data,
        table => +table.id === +receiptData.attributes.tableId,
      )

      halls.value[hallIdx].attributes.tables.data.splice(tableIdx, 1)

      vm.$toast.success(`${receiptData.meta.tableName}の会計をしました`)
      subscription.deleteOccupyPlace({ hallIdx, tableNumber: receiptData.meta.tableNumber })

      receipt.value = receiptData

      Promise.all([getClub(), fetchPlusMinusStats()], fetchOperationRateStats())
    }

    const moveTable = async (currentHallId, tableId, { hallId, tableNumber, callback }) => {
      try {
        const res = await TableApi.moveTable(tableId, { hallId, tableNumber })
        if (res?.data?.data) {
          const { data: tableData } = res.data
          const updatedHalls = [...halls.value]

          // 既存の場所を探す
          const hallIdx = findIndex(updatedHalls, hall => Number(hall.id) === Number(currentHallId))
          if (hallIdx < 0) return
          const tableIdx = findIndex(
            updatedHalls[hallIdx].attributes.tables.data,
            table => Number(table.id) === Number(tableData.id),
          )
          if (tableIdx < 0) return
          // 移動元のテーブルを削除
          updatedHalls[hallIdx].attributes.tables.data.splice(tableIdx, 1)

          // 移動先を探す
          const { hallId: newHallId } = tableData.attributes
          const newHallIdx = findIndex(updatedHalls, hall => Number(hall.id) === Number(newHallId))
          if (newHallIdx < 0) return
          // 移動先にテーブルを追加
          updatedHalls[newHallIdx].attributes.tables.data.push(tableData)

          halls.value = updatedHalls
          vm.$toast.success('卓を移動しました')
        }
      } catch (e) {
        console.error(e)
        vm.$toast.error('卓の移動に失敗しました')
      } finally {
        callback()
      }
    }

    // NOTE: 未精算の合計金額を算出する
    watch(() => cloneDeep(activeTables.value), (newVal, _prevVal) => {
      currentClubMeta.todaySalesUnfixed = sumBy(newVal, o => Number(o.meta.total))
    }, { immediate: true })

    initWith([
      getHalls(),
      getUsers(),
      getVendibles(),
      getReferrals(),
      getVendingContributionTriggers(),
      getTimecards(),
    ]).then(() => {
      if (route.value.query.tableId) {
        const tableToShow = find(activeTables.value, o => Number(o.id) === Number(route.value.query.tableId))
        targetTable.hallIdx = findIndex(halls.value, o => Number(o.id) === Number(tableToShow.attributes.hallId))
        targetTable.tableIdx = findIndex(hallTables.value[targetTable.hallIdx].tables, o => Number(o?.id) === Number(tableToShow.id))
        targetTableActions.show = true
      }
      // NOTE: hallデータ取得完了後に他のwaiterが操作しているデータを取得しセットする
      syncTables(currentHallIdx)
    })

    return {
      // data
      currentUserId,
      isLoading,
      currentHallIdx,
      targetTable,
      targetTableActions,
      users,
      vendibles,
      referrals,
      vendingContributionTriggers,
      receipt,

      // computed
      hallTables,
      activeTables,
      defaultTableShowTabIdx,
      courses,
      nominations,
      items,

      // methods
      openDrawer,
      openNewTable,
      openShowTable,
      closeDrawer,
      addTable,
      updateTable,
      checkoutTable,
      moveTable,

      // websocket
      subscription,
      noticeSubscription,
      actionType,

      usersWithTimecard,
    }
  },
}
</script>
